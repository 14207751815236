<template>
    <p>
        This is preview generator for Zoom Native Room Controls. Contribute to the project
        <a href="https://github.com/jeffderek/zoom-native-room-controls-preview">on GitHub</a>. If you find any
        discrepancy between the preview generated here and the actual Room Controls experience, please open an issue on
        GitHub or contact me directly.
    </p>
    <p>This project is developed and maintained by <a href="https://www.jeffmcaleer.com">Jeff McAleer</a></p>
    <p>This web site is not endorsed by, directly affiliated with, maintained, authorized, or sponsored by Zoom.</p>
</template>

<style lang="scss">
p {
    padding: 6px;
    font-size: 1.2rem;
}
</style>
